<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card class-name="iq-user-profile-block1">
          <template v-slot:body>
            <div class="user-details-block">
              <div class="user-profile text-center">
                <div class="profile-img-edit">
                  <b-img v-if="profilePicture" id="imageHolder" :src="profilePicture.thumbnailUrl" class="profile-pic height-150 width-150 profile-rounded" alt="profile-pic" @error="generateThumbnailImage(profilePicture.id)" style="object-fit: cover; max-width: 100%; max-height: 100%;" />
                  <b-img v-else id="imageHolder" src="https://s3.stellamedi.com/stellamedi-cdn/images/default-user-image.jpg"
                         class="profile-pic height-150 width-150" alt="default-profile-pic" style="object-fit: cover; max-width: 100%; max-height: 100%;" />
                  <div class="p-image" @click="$refs.profile_avatar.click()">
                    <div class="position-relative">
                      <i class="fa fa-pen"></i>
                      <input ref="profile_avatar" type="file" name="profile_avatar" class="h-100 position-absolute" accept=".png, .jpg, .jpeg" @change="onFileChanged" style="opacity: 0;">
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center mt-3">
                <h4>
                  <font-awesome-icon icon="fa-solid fa-rhombus"
                                     v-bind:class="customerInfo == null ? '' : customerInfo.customerTypeColor" /> &nbsp;
                  <b>{{ customerInfo == null ? '' : customerInfo.name }}</b><small v-if="customerInfo.age">, {{  customerInfo.age }}</small>&nbsp;
                  <img v-if="flagPath" :src="flagPath" style="width:25px;" />
                </h4>

                <p style="color: #393c52">
                  <span v-if="customerInfo.displayNumber"><b>ID:</b>&nbsp;{{  customerInfo.displayNumber }}</span>&nbsp;&nbsp;
                  <span v-if="customerInfo.cardNo"><font-awesome-icon icon="fa-solid fa-folder-open" /> {{  customerInfo.cardNo }}</span>&nbsp;&nbsp;
                  <span v-if="customerInfo.phone"><font-awesome-icon icon="fa-solid fa-phone" />{{  customerInfo.phone }}</span>
                </p>
              </div>
            </div>
            <b-nav pills fill>
              <b-nav-item v-for="(item,index) in menuItems" :key="index"
                          :to="{ name: item.routeName, params: {customerId:customerId} }" exact exact-active-class="active">
                {{ $t(item.displayName) }}
              </b-nav-item>
            </b-nav>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-row>
      <router-view :key="$router.currentRoute.meta.childName"></router-view>
    </b-row>
  </b-container>
</template>

<script>
  import customerService from '../../../services/customer'
  import multimediaService from '../../../services/multimedia';

  export default {
    name: 'CustomerSummary',
    props: {
      customerId: String
    },
    data() {
      return {
        menuItems: [],
        customerInfo: {},
        profilePicture: {},
        notAuthorized: false,
      }
    },
    methods: {
      getCustomerMenuItems() {
        customerService.getCustomerMenuItems().then((response) => {
          if (response) {
            this.menuItems = response;
          }
        })
      },
      getCustomerInfo() {
        customerService.getCustomerInfo(this.customerId).then((response) => {
          if (response && response.id == this.customerId) {
            this.customerInfo = response;
            this.notAuthorized = false;
          }
          else {
            this.notAuthorized = true;
          }
        })
      },
      getCustomerProfilePicture() {
        customerService.getCustomerProfilePicture(this.customerId)
          .then(response => {
            this.profilePicture = response;
          });
      },
      onFileChanged(event) {
        this.selectedFile = event.target.files[0];
        this.uploadProfilePicture();
      },
      uploadProfilePicture() {
        const formData = new FormData()
        formData.append('profilePicture', this.selectedFile, this.selectedFile.name)
        formData.append('customerId', this.customerId)
        formData.append('uploadedFrom', 'CustomerSummary')

        multimediaService.uploadProfilePicture(formData)
          .then(response => {
            if (response.success) {
              this.$toastr.success(this.$t("Success"));
              this.getCustomerProfilePicture();
            }
            else {
              this.$toastr.error(this.$t("Error"));
            }
          })
      },
      generateThumbnailImage(multimediaId) {
        multimediaService.generateThumbnailImage(multimediaId)
          .then((response) => {
            const thumbnailUrl = response;

            if (thumbnailUrl.length > 0) {
              document.getElementById(`imageHolder`).src = thumbnailUrl;
            }
          });
      },
    },
    watch: {
      customerId() {
        this.getCustomerInfo();
        this.getCustomerProfilePicture();
      }
    },
    computed: {
      flagPath() {
        let cdnUrl = '';
        if (this.customerInfo && this.customerInfo.countryAbbr) {
          cdnUrl = 'https://s3.stellamedi.com/stellamedi-cdn/images/country-flags/' + this.customerInfo.countryAbbr.toLowerCase() + '.png';
        }
        else {
          cdnUrl = 'https://s3.stellamedi.com/stellamedi-cdn/images/country-flags/zz.png';
        }
        return cdnUrl;
      }
    },
    mounted() {
      this.getCustomerMenuItems();
      this.getCustomerInfo();
      this.getCustomerProfilePicture();
    }
  }
</script>
<style>
  .profile-rounded {
    border-radius: 50%;
    height: 150px;
    width: 150px;
  }
</style>
